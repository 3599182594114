import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Image, PreviewContent, Content, ContactForm, Layout, Metadata } from '../components';

export function ContactPageTemplate({ title, content, isPreviewMode }) {
  const PageContent = isPreviewMode ? PreviewContent : Content;

  return (
    <>
      <section>
        <div className="container form">
          <h1>{title}</h1>
          <PageContent>{content}</PageContent>
          <ContactForm />
        </div>
      </section>
    </>
  );
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  isPreviewMode: PropTypes.bool,
};

ContactPageTemplate.defaultProps = {
  content: '',
  isPreviewMode: false,
};

const ContactPage = ({ data }) => {
  const { frontmatter, body } = data.mdx;

  const { metadata } = frontmatter;
  const title = metadata?.title.length > 1 ? metadata.title : frontmatter.title;
  const description = metadata?.description.length > 1 ? metadata.description : '';

  return (
    <Layout>
      <Metadata title={title} description={description} />
      <ContactPageTemplate
        title={frontmatter.title}
        content={body}
      />
    </Layout>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.shape({
      frontmatter: PropTypes.object,
      body: PropTypes.string,
    }),
  }).isRequired,
};

export default ContactPage;

export const ContactPageQuery = graphql`
  query ContactPageTemplate($id: String!) {
    mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        metadata {
          title
          description
        }
      }
    }
  }
`;
